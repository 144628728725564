p {
  font-family: "Source Sans Pro", sans-serif;
}

.sans {
  font-family: "Source Sans Pro", sans-serif;
}

.mont {
  font-family: "Montserrat", sans-serif;
}

.navbar-up ul > li > a {
  padding: 0px 5px;
}

.navbar-kelolabiz > ul > li {
  margin: 0px 10px;
}

.navbar-kelolabiz ul > li > a {
  color: #fff !important;
}

/* .navbar-kelolabiz-left li > a:hover {
    background-color: #ffce00;
} */

.navbar-kelolabiz-left li > a {
  transition: all 0.3s ease-in-out;
}

.navbar-kelolabiz-left li.active > a,
.navbar-kelolabiz-left li > a:hover {
  font-weight: 600 !important;
  /* background-color: #ffce00; */
}

.navbar-kelolabiz-right li > a {
  font-size: 0.8em;
}

.text-yellow {
  color: #ffce00 !important;
}

.button-green {
  background-color: #53b461;
  border-radius: 25px;
  padding: 0px 10px;
}

.button-green > a.nav-link,
.button-navy > a.nav-link {
  color: #fff !important;
}

.button-navy {
  background-color: #00317e;
  border-radius: 25px;
  padding: 0px 10px;
}

.navbar-kelolabiz-stick {
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 1px 11px 4px #0000000d;
}

.navbar-kelolabiz-menu-open ul > li > a,
.navbar-kelolabiz-stick ul > li > a {
  color: #00317e !important;
}

.img-gray {
  filter: grayscale(1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.img-gray:hover {
  filter: none;
}

.form-kelolabiz {
  border-radius: 25px !important;
  border: none !important;
  padding: 20px !important;
  font-family: "Source Sans Pro", sans-serif;
}

.select-kelolabiz {
  border-radius: 25px !important;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.select-form-kelolabiz {
  width: 100%;
  border: 0px;
  outline: none;
  /* padding: 20px; */
  background-color: #fff;
  color: #495057;
}

.btn-yelow {
  background-color: #ffce00 !important;
  border-radius: 25px !important;
  padding: 10px 25px !important;
  margin-top: 8px !important;
  font-weight: bold !important;
}

.list-bottom-kelola,
.bottom-menu-kelolabiz {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.list-bottom-kelola li::before {
  font-family: "Material Design Icons";
  content: "\F054";
  color: #333;
  margin-left: -35px;
  float: left;
  background-color: #ffce00;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0px;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  flex-direction: column;
  text-align: center;
}

.border-kelola {
  border-color: #4c4c4c !important;
}

.img-cursor {
  cursor: pointer;
}

.img-cursor:hover {
  opacity: 0.8;
}

.slick-slide {
  position: relative;
  z-index: 6;
}

.slick-center {
  z-index: 999;
}

.slick-center + .slick-slide {
  z-index: 8;
}

.my-node-enter {
  opacity: 0;
}

.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.my-node-exit {
  opacity: 1;
}

.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.dropdown.has-megamenu {
  position: static;
}

.menu-icon {
  display: inline-block;
  font-size: 4em;
}

.mega-menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mega-menu-list > li {
  padding: 5px 0px;
}

.mega-menu-list > li > a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.mega-menu-list > li > a:hover {
  background-color: transparent !important;
  font-weight: bold;
}

.text-navy {
  color: #273b91;
}

.text-lime {
  color: #8cc63f;
}

.text-teal {
  color: #008ac0;
}

.text-purple {
  color: #93278f;
}

.badge-pale {
  background-color: #badbdb;
  color: #fff;
}

.img-contact {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.background-fourth {
  background-image: url("../img/bg4.svg");
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  top: -25%;
  width: 100%;
  height: 55%;
  background-size: cover;
}

.background-third {
  background-image: url("../img//bg3.svg");
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  top: -8%;
  width: 100%;
  height: 40%;
  background-size: cover;
}

.badge-footer {
  background-color: rgb(255, 206, 0);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: rgb(51, 51, 51);
  display: inline-block;
  text-align: center;
  padding: 2px;
}

.banner-title {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
}

.banner-subtitle {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
}

.navbar-kelolabiz-wrap {
  padding: 0px 30px;
}

.border-right-md {
  border-right: 1px solid #676767;
}

.border-top-md {
  border-top: 1px solid #676767;
}

.img-feature {
  width: 100%;
  object-fit: cover;
  height: 280px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  object-position: top;
}

.sidebar {
  border-right: 1px solid #ffce00;
}

.sidebar-menu {
  padding: 0;
  list-style: none;
}

.sidebar-menu li > a {
  color: #00317e;
  padding: 5px 10px !important;
  display: inline-block;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.sidebar-menu li > a:hover,
.sidebar-menu li.active > a {
  text-decoration: none;
  background-color: #ffce00;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .border-right-md {
    border-right: none;
  }
  .img-contact {
    height: 80%;
  }
  .select-kelolabiz {
    padding: 0px;
  }
  .select-form-kelolabiz {
    padding: 10px;
  }
  .background-fourth {
    height: 40%;
  }
  .background-third {
    height: 55%;
  }
  .img-footer {
    width: 80px;
  }
  .text-footer {
    font-size: 0.6em;
  }
  .title-footer {
    font-size: 0.8em;
  }
  .subtitle-footer {
    font-size: 0.6em;
  }
  .list-bottom-kelola {
    font-size: 0.6em;
  }
  .list-bottom-kelola li::before {
    width: 15px;
    height: 15px;
    margin-left: -25px;
    margin-top: 10px;
  }
  .post-footer {
    height: 25px;
    overflow: hidden;
  }
  .bottom-menu-kelolabiz {
    font-size: 0.6em;
  }
  .bottom-menu-kelolabiz li {
    padding: 2.5px 0px;
  }
  .border-bottom-sm-grey {
    border-bottom: 1px solid #676767;
  }
  .border-top-sm-grey {
    border-top: 1px solid #676767;
  }
  .border-right-sm-grey {
    border-right: 1px solid #676767;
  }
  .badge-footer {
    width: 15px;
    height: 15px;
  }
  .banner-title {
    font-size: 2em;
  }
  .banner-subtitle {
    font-size: 0.8em;
  }
  .footer-copyright {
    font-size: 0.6em;
  }
  .navbar-kelolabiz-wrap {
    padding: 0px;
  }
  .navbar-kelolabiz-small {
    background-color: #53b461;
    font-size: 7px;
    font-weight: 600;
  }
  .navbar-kelolabiz-small li > a {
    color: #fff;
  }
  .banner-text-wrapper {
    text-align: center;
  }
  .navbar-kelolabiz-right {
    display: none;
  }
  .border-warning {
    border: none !important;
  }
}

.slick-prev {
  position: absolute;
  left: 20px;
  z-index: 10;
}

.slick-next {
  position: absolute;
  right: 20px;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
  font-weight: bold;
}

.navbar-kelolabiz-menu-open ul > li .dropdown > a,
.navbar-kelolabiz-stick ul > li .dropdown > a {
  color: #00317e !important;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
}
